/* eslint-disable brace-style */
<template>
  <v-container>
 <v-row justify="center">
      <v-col xs="12" sm="6" md="6" lg="4" xl="3">

              <v-row class="mt-1 mb-0" justify="center" :style="{position:'relative'}">
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                      :width="imgHeightWidth" :height="imgHeightWidth" viewBox="0 0 72 72"
                      :fill="dadaColors[currentDadaColorIndex]"
                      preserveAspectRatio="xMidYMid meet">

                      <g transform="translate(0,72) scale(0.007660,-0.008531)"
                      stroke="none">
                      <path ref="dada" :d="dadaSvgPath"/>
                      </g>
                    </svg>
                    <div :style="{width:$vuetify.breakpoint.mobile? '150px': '200px', height: whiteBoxHeight + 'px', position:'absolute',background:'white',bottom:'0px'}">

                    </div>
              </v-row>
              <v-row justify="center" class="mb-2">
                <span v-if="gameOver && !lose" :style="{color: 'var(--v-primary-base)'}">{{$t('winMsg')}}</span>
                <span v-if="gameOver && lose" :style="{color: 'var(--v-primary-base)'}">{{$t('lostMsg')}}</span>

              </v-row>

              <!-- row of blank letters for the selected word -->
              <v-row id="word" class="shortmargin ma-2" justify="center">
                <div
                  class="word-blankletter"
                  v-for="(letter, key) in wordDivs"
                  :key="key"
                  >
                    {{ letter }}
                  </div>
              </v-row>
              <v-row v-if="showHint" class="shortmargin  mb-1" no-gutters justify="center">
                {{`Hint: ${wordInfo.hindi} :: ${wordInfo.english}` }}
              </v-row>
              <v-row justify="center" class="mb-2" no-gutters>
                <v-col class="text-center">
                <HowToPlay :i18n_instructions_key="'paintDadaInstruction'"/>

                </v-col>
                <v-col  class="text-center">
                    <span v-if="!gameOver">{{`${maxGuessesPermitted - guesses} attempts left`}}</span>

                </v-col>
              </v-row>

              <!-- keyboard -->
              <v-row justify="center" no-gutters>
                <v-col class="ma-0 pa-1"
                  v-for="(row, key) in letters"
                  :key="'hangmankey'+key"
                >

                  <letter-button
                    :letter="row"
                    :game-over="gameOver"
                    :disableUponClick = "true"
                    :draggable="false"
                    :key="row"
                    @check="check(row)"
                    v-cloak />
                </v-col>
              </v-row>

              <v-row justify="center">
                  <v-col class="text-center">
                      <v-btn
                      v-if="showPlayAgain"
                      color="secondary"
                      raised="true"
                      @click="playAgain()"
                    >
                      {{$t('Play Again')}}
                    </v-btn>
                </v-col>
              </v-row>
              <v-row justify="center" align="center" class="ml-2 mr-2" no-gutters>
                <v-col class="ma-0 pa-0">
                  <v-btn
                  color="secondary"
                  raised="true"
                  @click="showHint = !showHint"
                >
                  Hint {{showHint ? $t('ON') : $t('OFF')}}
                </v-btn>
              </v-col>
              <v-col>
                <v-select class="pa-0 ma-0"
                  v-model="selectedLevel"
                  :items="levels"
                  item-text="text"
                  item-value="value"
                  :rules="[v => !!v || 'Item is required']"
                  required
                ></v-select>
              </v-col>
              </v-row>
            <GoBackButton/>
      </v-col>
 </v-row>
  </v-container>
</template>

<script>
import LetterButton from './DraggableLetterButton.vue'
import Utils from '../util/Utils.js'
import GoBackButton from './GoBackButton'
import HowToPlay from './HowToPlay.vue'

export default {
  components: {
    LetterButton,
    GoBackButton,
    HowToPlay
  },
  props: {
    showPlayAgain: {
      type: Boolean,
      default: true
    }

  },
  computed: {

    letters () {
      return [...this.$store.state.letters.map(l => l.guj), ...this.$store.state.vowels.map(v => v.guj)]
    },
    words () {
      return this.$store.state.wordList[this.selectedLevel]
    },
    wordInfo () {
      return this.$store.state.wordList[this.selectedLevel][this.currentWord.join('')]
    },
    imgHeightWidth () {
      return this.$vuetify.breakpoint.mobile ? 150 : 200
    }

  },
  filters: {
    capitalize: function (value) {
      return value.toUpperCase()
    }
  },
  data () {
    return {
      selectedLevel: 'level1',
      levels: [{ text: this.$t('Level') + ' 1', value: 'level1' }, { text: this.$t('Level') + ' 2', value: 'level2' }, { text: this.$t('Level') + ' 3', value: 'level3' }],
      currentWord: '',
      wordDivs: [],
      guesses: 0,
      maxGuessesPermitted: 10,
      gameOver: false,
      lose: false,
      twoPlayers: false,
      // will be set to the canvas element in mounted()
      canvas: '',
      // will be set to the canvas 2d context
      ctx: '',
      showHint: false,
      whiteBoxHeight: 0,
      dadaSvgPath:
        [
          'M4400 8434 c-25 -2 -106 -8 -180 -14 -259 -21 -728 -93 -829 -127 ',
          '-68 -22 -137 -70 -151 -103 -9 -21 -10 -64 -5 -147 3 -65 10 -389 14 -720 6 ',
          '-418 12 -607 20 -616 6 -8 8 -16 5 -20 -3 -3 4 -41 16 -84 19 -69 22 -106 24 ',
          '-326 2 -136 0 -250 -4 -252 -4 -2 -30 8 -57 22 -46 25 -53 26 -92 15 -65 -20 ',
          '-77 -47 -73 -167 1 -55 10 -127 18 -160 9 -33 24 -125 34 -205 26 -209 38 ',
          '-268 86 -434 45 -158 74 -206 132 -221 32 -8 77 11 89 37 7 16 13 -2 26 -83 ',
          '10 -57 37 -161 60 -231 l44 -127 -22 -23 c-13 -13 -35 -48 -51 -78 -18 -35 ',
          '-66 -93 -133 -160 -114 -114 -159 -177 -168 -237 -3 -21 -14 -47 -23 -58 -10 ',
          '-11 -27 -39 -38 -61 l-21 -42 -113 -21 c-62 -11 -144 -25 -183 -31 -85 -13 ',
          '-363 -72 -500 -106 -179 -44 -347 -104 -501 -180 -96 -47 -188 -83 -250 -100 ',
          '-115 -30 -159 -51 -195 -91 -27 -29 -138 -220 -272 -468 -67 -124 -90 -181 ',
          '-154 -380 -8 -27 -35 -84 -60 -126 -62 -108 -129 -278 -227 -581 -90 -277 ',
          '-142 -399 -224 -520 -67 -100 -184 -309 -231 -411 -53 -114 -80 -208 -146 ',
          '-502 -31 -137 -58 -260 -61 -272 l-6 -23 4701 0 c2586 0 4701 3 4701 6 0 19 ',
          '-66 230 -100 323 -22 58 -48 141 -59 184 -21 85 -46 132 -116 222 l-47 60 8 ',
          '100 c5 55 9 179 9 275 l0 175 -41 85 c-95 195 -120 278 -154 508 -38 263 -70 ',
          '393 -118 489 -20 39 -137 182 -296 361 -67 77 -74 88 -79 136 -6 59 -41 136 ',
          '-82 179 -39 41 -95 74 -209 122 -55 23 -167 74 -247 113 -224 108 -312 136 ',
          '-530 172 -68 12 -158 30 -199 40 -41 10 -154 35 -250 54 -251 50 -280 58 -280 ',
          '76 0 9 -16 30 -35 49 -19 18 -35 39 -35 47 0 23 -31 114 -41 120 -5 3 -9 14 ',
          '-9 24 0 10 -9 25 -20 33 -11 7 -40 45 -64 83 -62 97 -93 132 -137 155 -22 11 ',
          '-39 27 -39 36 0 9 -19 35 -43 57 -27 25 -48 57 -55 82 -6 22 -23 54 -37 70 ',
          '-21 24 -25 39 -25 91 0 59 -15 113 -31 113 -4 0 -17 21 -30 48 -12 26 -35 61 ',
          '-52 79 l-29 31 18 47 c15 41 19 44 25 27 17 -45 85 -55 156 -23 91 42 172 159 ',
          '244 351 23 63 49 122 56 132 18 20 55 129 90 262 23 86 27 119 25 223 -2 136 ',
          '-9 157 -67 193 -62 38 -141 34 -256 -14 -12 -5 -18 0 -24 21 -5 22 -18 262 ',
          '-16 308 9 216 9 278 0 302 -8 19 -11 165 -9 468 1 241 3 442 5 446 2 3 5 149 ',
          '6 324 l1 319 -23 19 c-119 99 -463 165 -1104 212 -210 16 -571 26 -655 19z'
        ],
      dadaColors: ['red', 'black', 'yellow', 'skyblue', 'green', 'darkorange', 'saddlebrown', 'deeppink', 'blueviolet'],
      currentDadaColorIndex: -1
    }
  },
  methods: {
    gujaratiWordSeperator: Utils.gujaratiWordSeperator,
    randomInteger: function (min, max) {
      return Math.floor(Math.random() * max)
    },
    changeDadaColor () {
      this.currentDadaColor = this.dadaColors[Math.floor(Math.random() * this.dadaColors.length - 1)]
    },
    // fill this.wordDivs with empty strings to create the orange blanks
    makeBlanks: function () {
      let word = this.gujaratiWordSeperator(this.currentWord.split(''))
      word = this.gujaratiWordSeperator(word)
      this.currentWord = word
      for (let i = 0; i < word.length; i++) {
        this.wordDivs.push('')
      }
    },
    // check the chosen letter when a letter component emits 'check'
    check: function (letter) {
      if (!this.gameOver) {
        let guessCorrect = false
        // check if the letter is in the word, if so, fill it in
        for (let i = 0; i < this.currentWord.length; i++) {
          if (this.currentWord[i] === letter || this.currentWord[i].indexOf(letter) >= 0) {
            this.wordDivs[i] = this.currentWord[i]
            guessCorrect = true
            this.drawDada()
          }
        }
        // if there are no more blanks in the word, you win
        if (!this.wordDivs.some(function (value) { return value === '' })) {
          this.gameOver = true
          Utils.dropConfetti(this.$confetti)
        }
        // if they guess wrong, draw the man
        if (!guessCorrect) {
          this.guesses++
          if (this.guesses === this.maxGuessesPermitted) {
            this.gameOver = true
            this.lose = true
            this.whiteBoxHeight = this.imgHeightWidth
            for (let i = 0; i < this.currentWord.length; i++) {
              this.wordDivs[i] = this.currentWord[i]
            }
          }
        }
      }
    },
    // re-initializes the game
    restart: function () {
      this.currentWord = this.getRandomWord()
      this.lose = false
      this.guesses = 0
      this.wordDivs = []
      this.gameOver = false
      this.makeBlanks()
      this.currentDadaColorIndex++
      if (this.currentDadaColorIndex === this.dadaColors.length) {
        this.currentDadaColorIndex = 0
      }
      this.drawDada()
    },
    // chooses a new word and resets the game when 'play again' is clicked
    playAgain: function () {
      this.lose = true
      this.gameOver = true
      setTimeout(() => {
        this.restart()
      }, 1000)
    },
    getRandomWord: function () {
      const words = Object.keys(this.words)
      let word = ''
      do {
        word = words[this.randomInteger(0, words.length - 1)]
      } while (Utils.gujaratiWordSeperator(word).length <= 3)
      return word
    },
    drawDada () {
      const whiteBoxHeightDecreaseBy = this.imgHeightWidth / (this.wordDivs.length + 1)
      this.whiteBoxHeight = this.imgHeightWidth - (whiteBoxHeightDecreaseBy * (this.wordDivs.filter(w => w !== '').length + 1))
    }
  },
  // identify the canvas element and initialize it, draw the gallows, choose a word, and draw the blanks.
  mounted: function () {
    this.restart()
  }

}
</script>

<style scoped>
/*
HANGMAN
https://github.com/joebeachjoebeach/hangman
Table of Contents
I. global element selectors
II. helper classes applied by js
III. the app
A. title bar / header
B. canvas
C. blank letters
D. keyboard
E. game-option buttons
*/
/* I. global element selectors */
html, body {
  align-items: center;
  background-color: #F5F5F6;
  color: #333;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
/* II. helper classes applied by js */
.hidden {
  display: none;
}
.orange {
  background-color: #ff9800;
}
/* III. the app and its contents */
#hangman {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  width: 100%;
}
/* B. canvas container */
#board {
  background-color: white;
  flex: 2 1 auto;
  margin: 0 0 0 0;
  max-width: 400px;
  width: 95%;
  min-height: 200px;
}
/* C. blank letters */
#word {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.word-blankletter {
  align-items: flex-end;
  background-color: var(--v-primary-base);
  color: white;
  box-shadow: 0 2px 5px #D0D0D0;
  display: flex;
  height: 50px;
  justify-content: center;
  margin: 0 2px 2px 2px;
  width: 40px;
  font-size: 30px;
}
/* D. keyboard */
#keyboard {
  margin-left: 10px;
  margin-right: 10px;
}
.keyboardWidth {
  max-width: 400px;
}
.keyboard-row-letter {
  height: 40px;
  width: 28px;
}
/* E. game-option buttons */
#buttons {
  display: flex;
  justify-content: space-between;
  margin: 0 0 5px 0;
  max-width: 700px;
  width: 95%;
}
.buttons-button {
  height: 25px;
  font-size: 1em;
  margin: auto;
}
.shortmargin {
  margin-bottom: 0px
}
</style>
